import AvatarImg from "@/components/AvatarImg.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import AddToBoardModal from "@/components/Crew/AddToBoardModal.vue";
import FilterModalContent from "@/components/FilterModalContent.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import { CrewStatus } from "@/enums";
import crewHttp from "@/http/crew";
import { crewStatus, filterHelper, helperMixin, infinityScrollTable, positionFilter, } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [
        helperMixin,
        crewStatus,
        filterHelper(),
        positionFilter(),
        infinityScrollTable(crewHttp(), {
            position_in: [],
        }),
    ],
    components: {
        FilterModalContent,
        BaseCheckbox,
        BaseBadge,
        ActionTableColumn,
        TeleportToContentFooter,
        BaseButtonPrimary,
        AddToBoardModal,
        AvatarImg,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        BaseFormGroup,
    },
    data() {
        return {
            selectedCrews: [],
            showAddToBoardModal: false,
            CrewStatus,
        };
    },
    computed: {
        selectedCrewStandby() {
            return (this.selectedCrews.filter((crew) => crew.status === CrewStatus.StandBy) || []);
        },
    },
    methods: {
        onAddToBoardDone() {
            this.selectedCrews = [];
            this.reload();
        },
    },
});
