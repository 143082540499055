import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseMessageError from "@/components/Base/BaseMessageError.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import crewHttp from "@/http/crew";
import fleetHttp from "@/http/fleet";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        BaseModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        CompanyLogo,
        AvatarImg,
        BaseMessageError,
    },
    mixins: [helperMixin, baseModal],
    emits: ["done"],
    props: {
        state: {
            type: String,
            default: "new schedule",
        },
        crews: {
            type: Array,
            required: true,
        },
    },
    computed: {
        crewHttp,
        fleetHttp,
    },
    data() {
        return {
            fleetId: "",
        };
    },
    methods: {
        onModalHide() {
            this.validationErrors = {};
        },
        async addToBoard() {
            if (await this.requestHandler(this.crewHttp.addToBoard({
                fleet_id: this.fleetId,
                crews: this.crews.map((data) => {
                    var _a, _b;
                    return ({
                        id: data.id,
                        sign_on: (_a = data.sign_on) !== null && _a !== void 0 ? _a : "",
                        sign_off: (_b = data.sign_off) !== null && _b !== void 0 ? _b : "",
                    });
                }),
            }))) {
                this.value = false;
                this.$emit("done");
            }
        },
    },
});
